import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { firestore } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Dashboard = () => {
  const [projects, setProjects] = useState([]);
  const [jobs, setJobs] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        const projectsQuery = query(collection(firestore, 'projects'), where('userId', '==', currentUser.uid));
        const jobsQuery = query(collection(firestore, 'jobs'), where('userId', '==', currentUser.uid));

        const [projectsSnapshot, jobsSnapshot] = await Promise.all([
          getDocs(projectsQuery),
          getDocs(jobsQuery)
        ]);

        setProjects(projectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setJobs(jobsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      }
    };

    fetchData();
  }, [currentUser]);

  const projectStatusCounts = projects.reduce((acc, project) => {
    acc[project.status] = (acc[project.status] || 0) + 1;
    return acc;
  }, {});

  const jobStatusCounts = jobs.reduce((acc, job) => {
    acc[job.status] = (acc[job.status] || 0) + 1;
    return acc;
  }, {});

  const projectStatusData = Object.entries(projectStatusCounts).map(([status, count]) => ({ name: status, value: count }));
  const jobStatusData = Object.entries(jobStatusCounts).map(([status, count]) => ({ name: status, value: count }));

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.toLocaleString('default', { month: 'short' })} ${d.getFullYear()}`;
  };

  const projectsByMonth = projects.reduce((acc, project) => {
    const monthYear = formatDate(project.creationDate);
    acc[monthYear] = (acc[monthYear] || 0) + 1;
    return acc;
  }, {});

  const projectTrendData = Object.entries(projectsByMonth)
    .map(([monthYear, count]) => ({ monthYear, count }))
    .sort((a, b) => new Date(a.monthYear) - new Date(b.monthYear));

  const jobsByMonth = jobs.reduce((acc, job) => {
    const monthYear = formatDate(job.creationDate);
    acc[monthYear] = (acc[monthYear] || 0) + 1;
    return acc;
  }, {});

  const jobTrendData = Object.entries(jobsByMonth)
    .map(([monthYear, count]) => ({ monthYear, count }))
    .sort((a, b) => new Date(a.monthYear) - new Date(b.monthYear));

  return (
    <div className="min-h-screen bg-gray-100 pt-20 pb-20">
      <div className="max-w-md mx-auto md:max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-gray-900">Dashboard</h1>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4">Project Overview</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-3xl font-bold text-blue-600">{projects.length}</p>
                <p className="text-sm text-gray-600">Total Projects</p>
              </div>
              <div>
                <p className="text-3xl font-bold text-green-600">{projectStatusCounts['Completed'] || 0}</p>
                <p className="text-sm text-gray-600">Completed Projects</p>
              </div>
              <div>
                <p className="text-3xl font-bold text-yellow-600">{projectStatusCounts['In Progress'] || 0}</p>
                <p className="text-sm text-gray-600">In Progress Projects</p>
              </div>
              <div>
                <p className="text-3xl font-bold text-red-600">{projectStatusCounts['Not Started'] || 0}</p>
                <p className="text-sm text-gray-600">Not Started Projects</p>
              </div>
            </div>
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4">Job Application Overview</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-3xl font-bold text-blue-600">{jobs.length}</p>
                <p className="text-sm text-gray-600">Total Applications</p>
              </div>
              <div>
                <p className="text-3xl font-bold text-green-600">{jobStatusCounts['Offer'] || 0}</p>
                <p className="text-sm text-gray-600">Job Offers</p>
              </div>
              <div>
                <p className="text-3xl font-bold text-yellow-600">{jobStatusCounts['Interview'] || 0}</p>
                <p className="text-sm text-gray-600">Interviews</p>
              </div>
              <div>
                <p className="text-3xl font-bold text-red-600">{jobStatusCounts['Rejected'] || 0}</p>
                <p className="text-sm text-gray-600">Rejected Applications</p>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4">Project Status Distribution</h2>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={projectStatusData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {projectStatusData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4">Job Application Status</h2>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={jobStatusData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {jobStatusData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4">Project Creation Trend</h2>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={projectTrendData}>
                <XAxis 
                  dataKey="monthYear" 
                  angle={-45} 
                  textAnchor="end" 
                  height={70} 
                  interval={0}
                  tick={{fontSize: 12}}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" name="Projects Created" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4">Job Application Trend</h2>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={jobTrendData}>
                <XAxis 
                  dataKey="monthYear" 
                  angle={-45} 
                  textAnchor="end" 
                  height={70} 
                  interval={0}
                  tick={{fontSize: 12}}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#82ca9d" name="Jobs Applied" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;