import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Navbar = () => {
  const location = useLocation();
  const history = useHistory();
  const { currentUser, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const menuRef = useRef(null);
  const profileDropdownRef = useRef(null);

  const isActive = (path) => {
    return location.pathname === path ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white';
  };

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavigation = (path) => {
    toggleMenu();
    history.push(path);
    window.scrollTo(0, 0);
  };

  const toggleProfileDropdown = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
      if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
        setIsProfileDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Hide Navbar on login and signup pages
  if (location.pathname === '/' || location.pathname === '/signup') {
    return null;
  }

  return (
    <>
      <header className="bg-gray-800 text-white p-4 md:p-4 fixed top-0 left-0 right-0 z-50">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-center md:justify-between">
          <div className="flex items-center justify-center w-full md:w-auto mb-4 md:mb-0">
            <img src={process.env.PUBLIC_URL + '/CDS.png'} alt="CDS Logo" className="h-12 md:h-10 w-auto mr-3" />
            <h1 className="text-2xl md:text-2xl font-bold">Coppola Development</h1>
          </div>
          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-4">
            <Link to="/dashboard" className={`px-3 py-2 rounded-md text-sm font-medium ${isActive('/dashboard')}`}>
              Dashboard
            </Link>
            <Link to="/project-tracker" className={`px-3 py-2 rounded-md text-sm font-medium ${isActive('/project-tracker')}`}>
              Project Tracker
            </Link>
            <Link to="/job-tracker" className={`px-3 py-2 rounded-md text-sm font-medium ${isActive('/job-tracker')}`}>
              Job Tracker
            </Link>
            {currentUser && (
              <div className="relative" ref={profileDropdownRef}>
                <button
                  onClick={toggleProfileDropdown}
                  className="bg-gray-700 hover:bg-gray-600 text-white px-3 py-2 rounded-md text-sm font-medium focus:outline-none"
                >
                  Profile
                </button>
                {isProfileDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
                    <Link to="/profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">View Profile</Link>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Log Out
                    </button>
                  </div>
                )}
              </div>
            )}
          </nav>
        </div>
      </header>

      {/* Mobile Navigation */}
      <div className="md:hidden">
        <button
          onClick={toggleMenu}
          className="fixed bottom-4 right-4 w-14 h-14 bg-blue-500 rounded-full flex items-center justify-center shadow-lg focus:outline-none z-50"
        >
          <svg
            className="w-6 h-6 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>

        {isMenuOpen && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-40">
            <div ref={menuRef} className="flex flex-col items-center justify-center h-full relative">
              <button
                onClick={toggleMenu}
                className="absolute top-4 right-4 text-white focus:outline-none"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
              <button
                onClick={() => handleNavigation('/dashboard')}
                className={`px-3 py-2 rounded-md text-lg font-medium ${isActive('/dashboard')} mb-4`}
              >
                Dashboard
              </button>
              <button
                onClick={() => handleNavigation('/project-tracker')}
                className={`px-3 py-2 rounded-md text-lg font-medium ${isActive('/project-tracker')} mb-4`}
              >
                Project Tracker
              </button>
              <button
                onClick={() => handleNavigation('/job-tracker')}
                className={`px-3 py-2 rounded-md text-lg font-medium ${isActive('/job-tracker')} mb-4`}
              >
                Job Tracker
              </button>
              {currentUser && (
                <>
                  <button
                    onClick={() => handleNavigation('/profile')}
                    className={`px-3 py-2 rounded-md text-lg font-medium ${isActive('/profile')} mb-4`}
                  >
                    Profile
                  </button>
                  <button
                    onClick={() => {
                      handleLogout();
                      toggleMenu();
                    }}
                    className="bg-gray-700 hover:bg-gray-600 text-white px-3 py-2 rounded-md text-lg font-medium mt-4"
                  >
                    Log Out
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
