import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { firestore } from '../firebase';
import { collection, query, where, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import JobModal from './JobModal';
import JobDetailsModal from './JobDetailsModal';
import TableView from './TableView';
import KanbanView from './KanbanView';
import { Squares2X2Icon, TableCellsIcon, ViewColumnsIcon } from '@heroicons/react/24/solid';

const JobTracker = () => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [statusFilter, setStatusFilter] = useState('All');
  const { currentUser } = useAuth();
  const [currentView, setCurrentView] = useState('card');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    fetchJobs();
  }, [currentUser]);

  useEffect(() => {
    filterJobs();
  }, [jobs, statusFilter]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile && currentView !== 'card') {
      setCurrentView('card');
    }
  }, [isMobile, currentView]);

  const fetchJobs = async () => {
    if (currentUser) {
      const q = query(collection(firestore, 'jobs'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(q);
      const jobsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      // Sort jobs by creationDate in descending order
      const sortedJobs = jobsData.sort((a, b) => 
        new Date(b.creationDate) - new Date(a.creationDate)
      );
      
      setJobs(sortedJobs);
    }
  };

  const filterJobs = () => {
    if (statusFilter === 'All') {
      setFilteredJobs(jobs);
    } else {
      setFilteredJobs(jobs.filter(job => job.status === statusFilter));
    }
  };

  const handleAddJob = async (jobData) => {
    try {
      await addDoc(collection(firestore, 'jobs'), {
        ...jobData,
        userId: currentUser.uid,
        creationDate: new Date().toISOString().split('T')[0],
        notes: jobData.notes || '' // Add this line
      });
      setIsModalOpen(false);
      fetchJobs();
    } catch (error) {
      console.error('Error adding job: ', error);
    }
  };

  const handleUpdateJob = async (updatedJob) => {
    try {
      const jobRef = doc(firestore, 'jobs', updatedJob.id);
      await updateDoc(jobRef, {
        ...updatedJob,
        notes: updatedJob.notes || '' // Add this line
      });
      setSelectedJob(null);
      fetchJobs();
    } catch (error) {
      console.error('Error updating job: ', error);
    }
  };

  const handleDeleteJob = async (jobId) => {
    try {
      await deleteDoc(doc(firestore, 'jobs', jobId));
      setSelectedJob(null);
      fetchJobs();
    } catch (error) {
      console.error('Error deleting job: ', error);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Applied':
        return 'bg-blue-100 text-blue-800 border-blue-300';
      case 'Interview':
        return 'bg-yellow-100 text-yellow-800 border-yellow-300';
      case 'Offer':
        return 'bg-green-100 text-green-800 border-green-300';
      case 'Rejected':
        return 'bg-red-100 text-red-800 border-red-300';
      default:
        return 'bg-gray-100 text-gray-800 border-gray-300';
    }
  };

  const statusOptions = ['All', 'Not Applied', 'Applied', 'Interview', 'Offer', 'Rejected'];

  const viewOptions = [
    { name: 'card', icon: Squares2X2Icon, label: 'Card View' },
    { name: 'kanban', icon: ViewColumnsIcon, label: 'Kanban View' },
    { name: 'table', icon: TableCellsIcon, label: 'Table View' },
  ];

  const renderViewOptions = () => {
    if (isMobile) {
      return null; // Don't render view options on mobile
    }

    return (
      <div className="flex space-x-2">
        {viewOptions.map((option) => (
          <button
            key={option.name}
            onClick={() => setCurrentView(option.name)}
            className={`p-2 rounded-md ${
              currentView === option.name
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            } transition-colors duration-200`}
            title={option.label}
          >
            <option.icon className="h-5 w-5" />
          </button>
        ))}
      </div>
    );
  };

  const renderJobs = () => {
    switch (currentView) {
      case 'kanban':
        return <KanbanView items={filteredJobs} onUpdateItem={handleUpdateJob} type="job" />;
      case 'table':
        return <TableView items={filteredJobs} onItemClick={setSelectedJob} type="job" />;
      default:
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredJobs.map(job => (
              <div
                key={job.id}
                className="bg-white rounded-2xl md:rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 cursor-pointer flex flex-col h-full"
                onClick={() => setSelectedJob(job)}
              >
                <div className="p-5 flex-grow overflow-hidden">
                  <h2 className="text-xl font-semibold text-gray-800 mb-2 truncate">{job.company}</h2>
                  <p className="text-gray-600 line-clamp-1"> <b>Position:</b> {job.position}</p>
                  <p className="text-gray-600 line-clamp-1"> <b>Location:</b> {job.location}</p>
                </div>
                <div className="px-5 py-3 bg-gray-50 flex justify-between items-center border-t border-gray-200">
                  <span className={`px-3 py-1 inline-flex text-sm font-semibold rounded-full ${getStatusColor(job.status)} border`}>
                    {job.status}
                  </span>
                  <span className="text-sm text-gray-500">Applied: {job.creationDate}</span>
                </div>
              </div>
            ))}
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 pt-20 pb-20">
      <div className="max-w-md mx-auto md:max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-gray-900">Your Job Applications</h1>
          <div className="flex items-center space-x-4">
            {renderViewOptions()}
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="bg-white border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {statusOptions.map(status => (
                <option key={status} value={status}>{status}</option>
              ))}
            </select>
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-green-500 hover:bg-green-600 text-white rounded-full w-10 h-10 flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition-colors duration-200"
              aria-label="Add new job"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
            </button>
          </div>
        </div>
        {filteredJobs.length === 0 ? (
          <div className="text-center py-10">
            <p className="text-xl text-gray-600">No Jobs Found</p>
            <p className="mt-2 text-gray-500">
              {statusFilter === 'All' 
                ? "Click the + button to add a new job application." 
                : "Try changing the filter or add a new job application."}
            </p>
          </div>
        ) : (
          renderJobs()
        )}
      </div>
      <JobModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleAddJob}
      />
      {selectedJob && (
        <JobDetailsModal
          isOpen={!!selectedJob}
          onClose={() => setSelectedJob(null)}
          onSubmit={handleUpdateJob}
          onDelete={handleDeleteJob}
          job={selectedJob}
        />
      )}
    </div>
  );
};

export default JobTracker;
