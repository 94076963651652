import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const KanbanView = ({ items = [], onUpdateItem, type }) => {
  if (!Array.isArray(items)) {
    console.error(`${type} prop is not an array:`, items);
    return <div>Error: Invalid data</div>;
  }

  const columns = {
    'Not Started': items.filter(item => item.status === 'Not Started'),
    'In Progress': items.filter(item => item.status === 'In Progress'),
    'Completed': items.filter(item => item.status === 'Completed'),
  };

  if (type === 'job') {
    columns['Not Applied'] = items.filter(item => item.status === 'Not Applied');
    columns['Applied'] = items.filter(item => item.status === 'Applied');
    columns['Interview'] = items.filter(item => item.status === 'Interview');
    columns['Offer'] = items.filter(item => item.status === 'Offer');
    columns['Rejected'] = items.filter(item => item.status === 'Rejected');
    delete columns['Not Started'];
    delete columns['In Progress'];
  }

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const item = items.find(i => i.id === draggableId);
    onUpdateItem({ ...item, status: destination.droppableId });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
      case 'Offer':
        return 'bg-green-100 text-green-800 border-green-300';
      case 'In Progress':
      case 'Interview':
        return 'bg-yellow-100 text-yellow-800 border-yellow-300';
      case 'Not Started':
      case 'Not Applied':
        return 'bg-red-100 text-red-800 border-red-300';
      case 'Applied':
        return 'bg-blue-100 text-blue-800 border-blue-300';
      case 'Rejected':
        return 'bg-gray-100 text-gray-800 border-gray-300';
      default:
        return 'bg-gray-100 text-gray-800 border-gray-300';
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex space-x-4 overflow-x-auto">
        {Object.entries(columns).map(([columnId, columnItems]) => (
          <div key={columnId} className="flex-shrink-0 w-80">
            <h2 className="font-bold mb-4 text-lg">{columnId}</h2>
            <Droppable droppableId={columnId}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="bg-gray-100 p-4 rounded-lg min-h-[200px]"
                >
                  {columnItems.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 mb-4"
                        >
                          <div className="p-5">
                            <h3 className="text-xl font-semibold text-gray-800 mb-2 truncate">
                              {type === 'job' ? item.company : item.name}
                            </h3>
                            {type === 'job' ? (
                              <>
                                <p className="text-gray-600 line-clamp-1"><b>Position:</b> {item.position}</p>
                                <p className="text-gray-600 line-clamp-1"><b>Location:</b> {item.location}</p>
                              </>
                            ) : (
                              <p className="text-gray-600 line-clamp-2">{item.description}</p>
                            )}
                          </div>
                          <div className="px-5 py-3 bg-gray-50 flex justify-between items-center border-t border-gray-200">
                            <span className={`px-3 py-1 inline-flex text-sm font-semibold rounded-full ${getStatusColor(item.status)} border`}>
                              {item.status}
                            </span>
                            <span className="text-sm text-gray-500">
                              {type === 'job' ? 'Applied:' : 'Created:'} {item.creationDate}
                            </span>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        ))}
      </div>
    </DragDropContext>
  );
};

export default KanbanView;