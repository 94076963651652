import React from 'react';

const TableView = ({ items = [], onItemClick, type }) => {
  if (!Array.isArray(items)) {
    console.error(`${type} prop is not an array:`, items);
    return <div>Error: Invalid data</div>;
  }

  const getHeaders = () => {
    if (type === 'job') {
      return ['Company', 'Position', 'Location', 'Status', 'Applied Date'];
    } else {
      return ['Name', 'Description', 'Status', 'Created Date'];
    }
  };

  const getRowData = (item) => {
    if (type === 'job') {
      return [
        item.company,
        item.position,
        item.location,
        item.status,
        item.creationDate
      ];
    } else {
      return [
        item.name,
        item.description,
        item.status,
        item.creationDate
      ];
    }
  };

  return (
    <div className="w-full">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {getHeaders().map((header, index) => (
              <th
                key={index}
                scope="col"
                className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                  header === 'Description' ? 'w-1/2' : ''
                }`}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {items.map((item) => (
            <tr
              key={item.id}
              onClick={() => onItemClick(item)}
              className="hover:bg-gray-100 cursor-pointer"
            >
              {getRowData(item).map((cell, index) => (
                <td key={index} className="px-6 py-4 whitespace-normal">
                  <div className={`text-sm text-gray-900 ${
                    getHeaders()[index] === 'Description' ? 'truncate max-w-xs' : ''
                  }`}>
                    {cell}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableView;