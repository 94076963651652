import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import ProjectTracker from './components/ProjectTracker';
import JobTracker from './components/JobTracker';
import Profile from './components/Profile';
import Login from './components/Login';
import Signup from './components/Signup';
import Navbar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Navbar /> {/* Navbar is here, outside of Switch but inside AuthProvider */}
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/signup" component={Signup} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/project-tracker" component={ProjectTracker} />
          <PrivateRoute path="/job-tracker" component={JobTracker} />
          <PrivateRoute path="/profile" component={Profile} />
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;
