import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAaZS8W730BVZtHRggLaMQ6BLKJcZ5Ihho",
  authDomain: "coppola-development.firebaseapp.com",
  projectId: "coppola-development",
  storageBucket: "coppola-development.appspot.com",
  messagingSenderId: "662434304907",
  appId: "1:662434304907:web:bd52881c3dcad71c72d58e"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);