import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { currentUser, loading } = useAuth();

  return (
    <Route
      {...rest}
      render={props => {
        if (loading) {
          return <div>Loading...</div>; // Or a loading spinner
        }
        return currentUser ? <Component {...props} /> : <Redirect to="/" />
      }}
    />
  )
};

export default PrivateRoute;
