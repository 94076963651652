import React, { useState, useEffect } from 'react';

const ProjectDetailsModal = ({ isOpen, onClose, onSubmit, onDelete, project }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedProject, setEditedProject] = useState(null);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);

  useEffect(() => {
    if (project) {
      setEditedProject(project);
    }
    setIsEditing(false);
  }, [project]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProject(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editedProject) {
      onSubmit(editedProject);
    }
    setIsEditing(false);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'bg-green-100 text-green-800';
      case 'In Progress':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-red-100 text-red-800';
    }
  };

  const statusOptions = ['Not Started', 'In Progress', 'Completed'];

  const handleStatusChange = (status) => {
    setEditedProject(prevData => ({ ...prevData, status }));
    setIsStatusDropdownOpen(false);
  };

  const handleDelete = () => {
    if (onDelete && typeof onDelete === 'function') {
      onDelete(project.id);
    } else {
      console.warn('onDelete function is not provided to ProjectDetailsModal');
    }
  };

  if (!isOpen || !project) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="project-modal">
      <div className="hidden hover:text-blue-700 hover:text-red-700 hover:text-gray-500"></div>
      <div className="relative top-28 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <h3 className="text-xl font-semibold text-gray-900 mr-3">Project Details</h3>
            <div className="flex space-x-2">
              <button
                onClick={() => setIsEditing(!isEditing)}
                className="text-blue-500 hover:text-blue-700 focus:outline-none transition-colors duration-200"
                title={isEditing ? "View project" : "Edit project"}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
              </button>
              <button
                onClick={handleDelete}
                className="text-red-500 hover:text-red-700 focus:outline-none transition-colors duration-200"
                title="Delete project"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
          </div>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none transition-colors duration-200"
            title="Close modal"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Project Name
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${!isEditing && 'bg-gray-100'}`}
              id="name"
              type="text"
              name="name"
              value={editedProject?.name || ''}
              onChange={handleChange}
              disabled={!isEditing}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
              Description
            </label>
            <textarea
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${!isEditing && 'bg-gray-100'}`}
              id="description"
              name="description"
              rows="3"
              value={editedProject?.description || ''}
              onChange={handleChange}
              disabled={!isEditing}
              required
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="status">
              Status
            </label>
            <div className="relative">
              <div
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${!isEditing ? 'bg-gray-100' : 'cursor-pointer'} flex justify-between items-center`}
                onClick={() => isEditing && setIsStatusDropdownOpen(!isStatusDropdownOpen)}
              >
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(editedProject?.status)}`}>
                  {editedProject?.status}
                </span>
                {isEditing && (
                  <svg className="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                )}
              </div>
              {isEditing && isStatusDropdownOpen && (
                <div className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg">
                  <div className="py-1">
                    {statusOptions.map((status) => (
                      <div
                        key={status}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleStatusChange(status)}
                      >
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(status)}`}>
                          {status}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="creationDate">
              Creation Date
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${!isEditing && 'bg-gray-100'}`}
              id="creationDate"
              type="date"
              name="creationDate"
              value={editedProject?.creationDate || ''}
              onChange={handleChange}
              disabled={!isEditing}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="notes">
              Notes
            </label>
            <textarea
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${!isEditing && 'bg-gray-100'}`}
              id="notes"
              name="notes"
              rows="3"
              value={editedProject?.notes || ''}
              onChange={handleChange}
              disabled={!isEditing}
              placeholder="Add any additional notes here"
            ></textarea>
          </div>
          <div className="flex items-center justify-center">
            {isEditing && (
              <>
                <button
                  type="submit"
                  className="text-green-500 hover:text-green-700 focus:outline-none mr-2"
                  title="Save changes"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </button>
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className="text-red-500 hover:text-red-700 focus:outline-none"
                  title="Discard changes"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProjectDetailsModal;


